import * as R from 'ramda';
import * as L from './Lang';

export const TICKET_GROUP_TYPES = {
  normal: 'normal',
  package: 'package',
  bank: 'bank',
  group: 'group',
  free: 'free',
  disabled: 'disabled',
};

export const getTicketsGroupsConfig = (isPaidBooking) => {
  return isPaidBooking
    ? [
        {name: '一般票種', value: 'normal'},
        {name: '套票', value: 'package'},
        {name: '銀行優惠', value: 'bank'},
      ]
    : [
        {name: '免費兌換券', value: 'free'},
        {name: '愛心敬老', value: 'disabled'},
        {name: '團體兌換券', value: 'group'},
      ];
};

export const getTicketsByPaidType = (filmAvailableTicketList, isPaidBooking) =>
  isPaidBooking
    ? filmAvailableTicketList.filter(
        (t) => t.LongDescription.toLocaleUpperCase() === '',
      )
    : filmAvailableTicketList.filter(
        (t) => t.LongDescription.toLocaleUpperCase() === 'U',
      );

export const getTicketsByFilterWithTicketGroupType = (
  tickets,
  ticketGroupType,
) => {
  if (ticketGroupType === TICKET_GROUP_TYPES.bank) {
    return tickets.filter((t) => t.IsCardPaymentPromotionTicket);
  } else if (ticketGroupType === TICKET_GROUP_TYPES.normal) {
    return tickets.filter(
      (t) => !t.IsCardPaymentPromotionTicket && !t.IsPackageTicket,
    );
  } else if (ticketGroupType === TICKET_GROUP_TYPES.package) {
    return tickets.filter((t) => t.IsPackageTicket);
  } else if (ticketGroupType === TICKET_GROUP_TYPES.free) {
    return tickets.filter(
      (t) => !t.EnforceUseOfBarcode && t.IsRedemptionTicket,
    );
  } else if (ticketGroupType === TICKET_GROUP_TYPES.group) {
    return tickets.filter((t) => t.EnforceUseOfBarcode && t.IsRedemptionTicket);
  } else if (ticketGroupType === TICKET_GROUP_TYPES.disabled) {
    return tickets.filter(
      (t) => !t.EnforceUseOfBarcode && !t.IsRedemptionTicket,
    );
  } else {
    return [];
  }
};

export const getIsEnforceUseBarCode = (
  filmAvailableTicketList,
  currentSelectedTickets,
) => {
  const ticketTypeCodes = currentSelectedTickets.map((t) => t.ticketTypeCode);
  let isEnforce = false;
  for (let code of ticketTypeCodes) {
    const targetTicket = filmAvailableTicketList.find(
      (t) => t.ticketTypeCode === code,
    );
    if (targetTicket.EnforceUseOfBarcode) {
      isEnforce = targetTicket.EnforceUseOfBarcode;
      break;
    }
    isEnforce = targetTicket.EnforceUseOfBarcode;
  }
  return isEnforce;
};

export const getAvailableSelectSeatsCount = (
  filmAvailableTicketList,
  currentSelectedTickets,
) => {
  let result = 0;
  for (let ticket of currentSelectedTickets) {
    const targetTicket = filmAvailableTicketList.find(
      (t) => t.ticketTypeCode === ticket.ticketTypeCode,
    );
    if (targetTicket.IsPackageTicket) {
      result += targetTicket.PackageContent.Tickets.reduce((acc, cur) => {
        acc += cur.Quantity * ticket.quantity;
        return acc;
      }, 0);
    } else {
      result += ticket.quantity;
    }
  }
  return result;
};

export function getConcessionsInfo(filmAvailableTickets, values) {
  const ticketTypeCodes = values.tickets.map((t) => t.ticketTypeCode);
  const packageConcessions = ticketTypeCodes.reduce((acc, cur) => {
    acc = [
      ...acc,
      filmAvailableTickets.find((t) => t.ticketTypeCode === cur).PackageContent
        .Concessions,
    ];
    return acc;
  }, []);

  const result = R.flatten(packageConcessions)
    .map((meal) => ({
      id: meal.Id,
      title: L.d({zh: meal.DescriptionAlt, en: meal.Description}),
      subtitle: L.d({
        zh: meal.ExtendedDescriptionAlt,
        en: meal.ExtendedDescription,
      }),
      imgUrl: '',
    }))
    .concat(
      values.meals.map((meal) => ({
        id: meal.id,
        title: meal.title,
        subtitle: meal.subtitle,
        imgUrl: meal.imgUrl || '',
      })),
    );

  return R.uniqWith((prev, next) => prev.id === next.id)(result);
}

export function getOrderSeatsInfo(tickets) {
  return tickets
    .reduce((acc, cur) => {
      const seats = cur.SeatData.split(',').map((s) => s.trim());
      acc = [...acc, ...seats];
      return acc;
    }, [])
    .map((s) => {
      return {
        row: s.match(/^[A-Z]/)[0],
        column: s.match(/\d+$/)[0],
      };
    })
    .map((s, index, array) => {
      if (array.length - 1 === index) {
        return `${s.row} 排 ${s.column}號`;
      }
      return `${s.row} 排 ${s.column}號 / `;
    });
}

export function getListByFilterSalesChannels(resp) {
  // WWW meals web
  return resp.filter((item) => {
    if (Array.isArray(item.SalesChannels)) {
      return item.SalesChannels.includes('WWW');
    } else {
      return item.SalesChannels.indexOf('WWW');
    }
  });
}
