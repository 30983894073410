import {useOutlet} from 'reconnect.js';
import {useEffect, useState} from 'react';
import moment from 'moment';
import Config from '../../data.json';
import {getListByFilterSalesChannels} from '../Utils/Ticket';

export const formatFilm = (f) => ({
  ...f,
  id: f.ID,
  image: `${Config.filmPosterCdn}${f.ScheduledFilmId}`,
  video:
    f.TrailerUrl || 'https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
  caption: {
    title: f.TitleAlt,
    subtitle: f.Title,
  },
  runTime: f.RunTime,
  cinemaName: f.CinemaName,
  synopsis: f.Synopsis,
  category: {
    zh: f.SynopsisAlt.split(',')[0] || '',
    en: f.Synopsis.split(',')[0] || '',
  },
  director: {
    zh: f.SynopsisAlt.split(',')[1] || '',
    en: f.Synopsis.split(',')[1] || '',
  },
  cast: {
    zh: f.SynopsisAlt.split(',')[2] || '',
    en: f.Synopsis.split(',')[2] || '',
  },
  ratingDescription: {
    zh: f.RatingDescriptionAlt,
    en: f.RatingDescription,
  },
  openingDate: f.OpeningDate,
  rating: f.Rating,
  ratingAlt: f.RatingAlt,
  sessions: getListByFilterSalesChannels(f.Sessions)
    .sort((a, b) => moment(a.Showtime).isAfter(b.Showtime))
    .map((s) => {
      return {
        ...s,
        id: s.SessionId,
        seatsAvailables: s.SeatsAvailable,
        filmPlayType: s.Attributes[0]?.Description,
        screenNumber: s.ScreenNumber,
        showtime: moment(s.Showtime).toISOString(),
        endtime: moment(s.Showtime)
          .add(parseInt(f.RunTime, 10), 'minutes')
          .toISOString(),
        showDate: moment(s.Showtime).format('YYYY-MM-DD'),
      };
    }),
});

export const useFilmList = ({cinemaId}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [actions] = useOutlet('actions');
  const [filmList, setFilmList] = useState([]);

  useEffect(() => {
    const fetchFilmList = async () => {
      try {
        setIsLoading(true);
        const resp = await actions.fetchFilmList({cinemaId: cinemaId});
        setFilmList(resp.map(formatFilm));
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFilmList().then(() => 0);
  }, [actions, cinemaId]);

  return {
    isLoading,
    filmList,
  };
};
