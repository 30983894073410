import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useForm} from 'react-hook-form';
import {useFilmList} from '../../Hooks/useFilmList';
import {Breakpoints, Color, DAYS_DISPLAY, INPUT_TYPES} from '../../constants';
import * as L from '../../Utils/Lang';
import * as R from 'ramda';
import moment from 'moment';
import {Input} from '../../Components/Input';
import {RectButton} from '../../Components/RectButton';
import Spinner from '../../Components/Spinner';
import {
  FlexColumnCenterBetween,
  FlexRowCenterBetween,
  FlexRowCenterStart,
  FlexRowStartStart,
  Text12,
} from '../../widget';
import {navigate} from 'gatsby-link';

const BulkSearchPage = (props) => {
  const bulkSearchForm = useForm();
  const {filmList, isLoading} = useFilmList({cinemaId: 1001});
  const [result, setResult] = useState([]);
  const [values, setValues] = useState({
    date: '',
  });

  const FIELD = useMemo(
    () => [
      {
        placeholder: '請選擇電影',
        name: 'bulkFilmList',
        defaultValue: [],
        rules: {
          required: true,
        },
        message: {
          required: '電影欄位不可為空',
        },
        label: '查詢電影',
        type: INPUT_TYPES.multiSelect,
        options: filmList.map((f) => ({
          label: L.d({zh: f.caption.title, en: f.caption.subtitle}),
          value: f.id,
        })),
      },
    ],
    [filmList],
  );

  const aggregateResultShowDate = useMemo(() => {
    return R.pipe(
      R.reduce((acc, cur) => [...acc, ...cur.sessions], []),
      R.map((s) => s.showDate),
      R.uniqWith(R.eqBy(String)),
      R.sort((a, b) => moment(a).isAfter(moment(b))),
    )(result);
  }, [result]);

  useEffect(() => {
    if (aggregateResultShowDate.length > 0) {
      setValues({
        date: moment(aggregateResultShowDate[0]).format('YYYY-MM-DD'),
      });
    }
  }, [aggregateResultShowDate]);

  const filmsHasSessionsOnTheDate = useMemo(() => {
    return R.pipe(
      R.reduce((acc, cur) => {
        if (
          R.any((s) => moment(s.showDate).format('YYYY-MM-DD') === values.date)(
            cur.sessions,
          )
        ) {
          acc.push(cur);
        }
        return acc;
      }, []),
      R.sort(
        (a, b) =>
          parseInt(a.ScheduledFilmId, 10) - parseInt(b.ScheduledFilmId, 10),
      ),
    )(result);
  }, [result, values.date]);

  const onSubmit = async (data) => {
    const {bulkFilmList} = data;
    const selectedFilms = bulkFilmList.map((id) =>
      filmList.find((f) => f.id === id),
    );
    setResult(selectedFilms);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      {result.length <= 0 && (
        <div className="view">
          <Input
            style={{marginBottom: 10}}
            key={FIELD[0].name}
            field={FIELD[0]}
            control={bulkSearchForm.control}
            errors={bulkSearchForm.formState.errors}
          />
          <RectButton
            style={{width: '100%'}}
            onClick={bulkSearchForm.handleSubmit(onSubmit)}>
            查詢
          </RectButton>
        </div>
      )}
      {result.length > 0 && (
        <div className="view">
          <Text12 style={{margin: '10px 0'}}>選擇日期</Text12>
          <div className="horizontal-scroll-container">
            {aggregateResultShowDate.map((s, i) => {
              const today = moment(new Date()).format('MM-DD');
              const showDate = moment(s).format('MM-DD');
              const showDateLong = moment(s).format('YYYY-MM-DD');
              const isToday = today === showDate;
              const active = showDateLong === values.date;
              return (
                <RectButton
                  key={i}
                  onClick={() => {
                    setValues((prev) => ({
                      ...prev,
                      date: showDateLong,
                    }));
                  }}
                  style={{
                    marginRight: 10,
                    backgroundColor: active ? Color.orange : Color.black_10,
                    color: active ? Color.white : Color.black,
                    width: 50,
                    height: 50,
                  }}>
                  {isToday ? (
                    <div>今日</div>
                  ) : (
                    <div className="date-inner-content">
                      <div className="month">
                        {parseInt(moment(s).format('MM'), 10)}月
                      </div>
                      <div className="day">{moment(s).format('DD')}</div>
                      <div className="dayOfWeek">
                        ({L.d(DAYS_DISPLAY[moment(s).get('day')])})
                      </div>
                    </div>
                  )}
                </RectButton>
              );
            })}
          </div>

          <Text12 style={{margin: '10px 0'}}>選擇電影與場次</Text12>
          <div className="bulk-search-list">
            {filmsHasSessionsOnTheDate.map((f, i) => {
              const isLast = i === filmsHasSessionsOnTheDate.length - 1;
              return (
                <div className="bulk-search-item" key={i}>
                  <div className="context">
                    <img
                      src={f.image}
                      alt="film poster"
                      onError={(e) => {
                        e.target.src =
                          'https://fakeimg.pl/282x400/?text=404&font=lobster';
                      }}
                    />
                    <div className="film-context">
                      <div className="title">{f.caption.title}</div>
                      <div className="subtitle">{f.caption.subtitle}</div>
                    </div>
                  </div>
                  <div className="sessions-container">
                    {f.sessions
                      .filter(
                        (s) =>
                          moment(s.showtime).format('YYYY-MM-DD') ===
                          values.date,
                      )
                      .map((s, i) => {
                        return (
                          <RectButton
                            onClick={async () => {
                              await navigate(
                                `/book?id=${f.id}&defaultSessionId=${s.id}`,
                              );
                            }}
                            key={i}
                            style={{
                              width: 120,
                              height: 50,
                              marginRight: 10,
                              marginBottom: 10,
                              color: Color.black_70,
                              backgroundColor: Color.black_10,
                            }}>
                            <div className="session-inner-content">
                              <div className="top">
                                <div className="start-time">
                                  {moment(s.showtime).format('HH:mm')}
                                </div>
                                <div>-</div>
                                <div className="end-time">
                                  {moment(s.endtime).format('HH:mm')}
                                </div>
                              </div>
                              <div className="bottom">
                                <div className="screen">
                                  第 {s.screenNumber} 廳
                                </div>
                                <div className="available-seats">
                                  剩 {s.seatsAvailables} 座位
                                </div>
                              </div>
                            </div>
                          </RectButton>
                        );
                      })}
                    {new Array(8).fill(0).map((s, i) => (
                      <div
                        key={i}
                        style={{width: 120, height: 1, marginRight: 10}}
                      />
                    ))}
                  </div>
                  {!isLast && <div className="separate" />}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  min-height: calc(100vh - var(--topNavBarHeight));
  & > .view {
    flex: 1;
    display: flex;
    flex-direction: column;
    & > .horizontal-scroll-container {
      ::-webkit-scrollbar {
        display: none;
      }
      ${FlexRowCenterStart};
      max-width: 100%;
      overflow-x: scroll;
      .date-inner-content {
        ${FlexColumnCenterBetween};
        & > .month,
        .dayOfWeek {
          font-size: 8px;
        }
        & > .day {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    & > .bulk-search-list {
      & > .bulk-search-item {
        margin-bottom: 20px;
        & > .context {
          ${FlexRowStartStart};
          margin-bottom: 10px;
          & > img {
            width: 70px;
            height: 108px;
            background-size: cover;
            border-radius: 10px;
            object-fit: cover;
          }
          & > .film-context {
            padding-left: 10px;
          }
        }
        & > .sessions-container {
          max-width: 100%;
          ${FlexRowCenterBetween};
          flex-wrap: wrap;
          .session-inner-content {
            width: 100%;
            ${FlexColumnCenterBetween};
            & > .top {
              width: 100%;
              ${FlexRowCenterBetween};
              & > .start-time {
                font-size: 12px;
              }
              & > .end-time {
                font-size: 10px;
              }
            }
            & > .bottom {
              width: 100%;
              ${FlexRowCenterBetween};
              font-size: 10px;
              font-weight: 300;
            }
          }
        }
        & > .separate {
          width: 100%;
          height: 1px;
          background-color: ${Color.black_10};
        }
      }
    }
  }
  @media screen and (min-width: ${Breakpoints.sm}px) {
    & > .view {
      & > .bulk-search-list {
        & > .bulk-search-item {
          & > .context {
            & > img {
              width: 140px;
              height: 216px;
              background-size: cover;
              border-radius: 10px;
            }
            & > .film-context {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
`;

export default BulkSearchPage;
